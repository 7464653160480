import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import {
  getWithdrawList,
  cancelWithdrawList,
} from "../../../../redux/user/actions";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
const WithdrawList = () => {
  const dispatch = useDispatch();
  const { withdrawList } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  useEffect(() => {
    dispatch(getWithdrawList());
  }, []);

  const handleRequestCancel = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };
  const cancelRequest = async () => {
    setShowModal(false);
    cancelWithdrawList({ id: selectedItem?.id })
      .then((response) => {
        if (response.data.error === false) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request Success");
          dispatch(getWithdrawList());
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, "Request Failed");
        }
      })
      .catch((err) => {
        Toaster(
          APP_CONST.TOAST_TYPE.ERROR,
          err.response.data.message || "Request Failed"
        );
      });
  };
  return (
    <>
      <HeaderAfterLogin />
      <main className="main mt-3">
        <div className="container table-responsive">
          <table className="table text-white">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Amount</th>
                <th scope="col">Account Name</th>
                <th scope="col">Account No</th>
                <th scope="col">Ifsc code</th>
                <th scope="col">Bank Name</th>
                <th scope="col">Mobile No</th>
                <th scope="col">status</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {withdrawList &&
                withdrawList.length &&
                withdrawList.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>

                      <td>{item?.amount}</td>
                      <td>{item?.accountName}</td>
                      <td>{item?.accountNo}</td>
                      <td>{item?.ifscCode}</td>
                      <td>{item?.bankName}</td>
                      <td>{item?.mobileNo}</td>
                      <td>{item?.status}</td>
                      <td>{moment(item?.createdAt).format("DD-MM-YY h:mm")}</td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => handleRequestCancel(item)}
                          disabled={item?.status !== "pending"}
                        >
                          Cancel
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </main>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ color: "black !important" }}
        className="withdrawList"
      >
        <Modal.Header>
          <Modal.Title style={{ color: "#000" }}>
            Please confirm to cancel the request
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body style={{ color: "black !important" }}></Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={cancelRequest}>
            Cancel request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WithdrawList;
