import React from "react";
import numberWithCommas from "../../utils/numbersWithComma";

export default function WalletCard({ wallet }) {
  return (
    <ul className="wallet-card">
      <li className="casino_bal">
        Casino <span>{numberWithCommas(wallet?.cash || 0)}</span>
      </li>
      <li className="bonus_bal">
        Bonus <span>{numberWithCommas(wallet?.coins || 0)}</span>
      </li>
      {/* <li className="sports_bal">
        Sports <span>{numberWithCommas(wallet?.sports || 0)}</span>
      </li> */}
    </ul>
  );
}
