import React from "react";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Header from "../../../../containers/Header";
import Facebook from "../../../../assets/images/facebook-512.svg";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";

const BeforeContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main contact">
          <div className="container">
            <div className="PagesHeading">
              <h2>Contact Us</h2>
            </div>
            <div className="payment-info">
              <div class="social-icons contact-social">
                <ul>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {/* <span className="notification-count">2</span> */}
                      <img src={Whatsapp} alt="Whatsapp Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.TELEGRAM != ""
                          ? appDetails?.TELEGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Telegram} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src={Facebook} alt="Instagram Icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BeforeContactUs;
