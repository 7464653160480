import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { SlotGames } from "../../../../lib/data/SlotGames.data";
import BackIcon from "../../../../assets/svg/BackIcon";

const Slots = () => {
  const categoriesTab = [
    { name: "Netent", key: "netenttab" },
    { name: "RedTiger", key: "redtigertab" },
  ];
  const [activeCategory, setActiveCategory] = useState("netenttab");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredData(getActiveCategoryGames("netenttab"));
  }, []);

  const getActiveCategoryGames = (category) => {
    return Object.values(SlotGames[category] || {}).filter(
      (game) => typeof game === "object" && game.name
    );
  };

  return (
    <>
      <HeaderAfterLogin />
      <main className="main slots-section">
        <div className="container">
          <div className="PagesHeading">
            <h4>Slots</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <ul className="slots-tabs">
            {categoriesTab.map((tab, index) => (
              <li key={index}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setActiveCategory(tab.key);
                    setFilteredData(getActiveCategoryGames(tab.key));
                  }}
                  className={activeCategory === tab.key ? "active" : ""}
                >
                  {tab.name}
                </a>
              </li>
            ))}
          </ul>

          <ul className="games-list">
            {filteredData.length > 0 ? (
              filteredData.map((game, idx) => (
                <li key={game.code || idx} className="game-item">
                  <a href={game.redirectUrl || "javascript:void(0)"}>
                    <img src={game.imgUrl} alt={game.name || ""} />
                    <span>{game.name}</span>
                  </a>
                </li>
              ))
            ) : (
              <li>No games available</li>
            )}
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Slots;
