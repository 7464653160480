import React from "react";
import logo from "../assets/images/logo_.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import { axiosService } from "../utils/axiosService";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import Aviator from "../assets/images/icons/airplane-mode.png";
import SlotsIcon from "../assets/images/icons/slots.png";

import ProfileIConNew from "../assets/images/icons/user.png";
import SportsIConNew from "../assets/images/icons/football.png";
import CasinoIconNew from "../assets/images/icons/casino-chip.png";
import HomeIconNew from "../assets/images/icons/building.png";

const Footer = () => {
  const handleExchangeLaunch = () => {
    // console.log({ mobile: device?.device?.type === "smartphone" });
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      console.log("res", res?.data?.data?.lobbyUrl);
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };

  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-links mb-5">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="footer-logo">
          <a href="#">
            <img src={logo} />
          </a>
        </div>
        <p>{appDetails?.FOOTER_TEXT || ""}</p>

        <div className="social-icons">
          <ul>
            {/* <li>
              <a
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <FbIcon />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <InstaIcon />
              </a>
            </li> */}
            <li>
              <a
                className="foot-whatsapp"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-50 w-100">
          <ul className="d-flex justify-content-around m-0 p-0 list-unstyled">
            <li>
              <a href="/games-all">
                <img src={CasinoIconNew} alt="CasinoIconNew" />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a href="/Slots">
                <img src={SlotsIcon} alt="slots" />
                <span>Slots</span>
              </a>
            </li>
            <li className="home">
              <a className="active" href="/home">
                <img src={HomeIconNew} alt="HomeIconNew" />
                <span>Home</span>
              </a>
              <a href="/casino/spribe/aviator" className="aviatorImg">
                <img src={Aviator} alt="aviator" />
                <span>Aviator</span>
              </a>
            </li>
            <li>
              <a href="/sports/Inplay">
                <img src={SportsIConNew} alt="SportsIConNew" />
                <span>Sports</span>
              </a>
            </li>

            <li>
              <a href="/profile">
                <img src={ProfileIConNew} alt="ProfileIConNew" />
                <span>Profile</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
